import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import gsap from "gsap";

import useLanguage from "../../hooks/useLanguage";
import useWallet from "../../hooks/useWallet";
import ValueIcons from "../../items/ValueIcons";
import ValueDisplay from "../../items/ValueDisplay";
import CurrencyIcons from "../../items/CurrencyIcons";

import st from "../../styles/components/popup.module.scss";

// Manages the popup messages
export const PopupItem = forwardRef(
  ({ displayItem, setList, lastHeightRef }, ref) => {
    const L = useLanguage(false);
    const [created, setCreated] = useState(false);
    const barRef = useRef(null);
    const contentRef = useRef(null);
    const timeline = useRef(null);
    const [popupDetails, setPopupDetails] = useState({});
    const { activeRate } = useWallet();

    // Reset timer when a duplicate is detected
    useImperativeHandle(ref, () => ({
      myMethod: () => {
        timeline.current.progress(0.04);
      },
    }));

    // Setup the timeline
    useEffect(() => {
      // Popup Properties
      // Type: 0,1,2 0 is negative, 1 is positive, 2 is caution
      // Icon: optional
      // Run only once when first created

      // Create timeline to animate items in and remove them

      let topValue = lastHeightRef.current + 10 + "px";
      timeline.current = gsap.timeline({
        paused: true,
        onComplete: () =>
          setList((item) => {
            let newItem = item.filter(
              (item) => item.key !== displayItem.itemId
            );
            return newItem;
          }),
      });

      timeline.current
        .set(contentRef.current, { y: topValue })
        .to(contentRef.current, { duration: 0.2, x: "0%", alpha: 1 })
        .to(barRef.current, {
          duration: displayItem.duration / 1000, // Use the duration from the displayItem
          width: "0%",
          ease: "Linear.easeNone",
        })
        .set(contentRef.current, { pointerEvents: "none" })
        .to(contentRef.current, { duration: 0.2, x: "1300px", alpha: 0 });

      timeline.current.play();
      setTimeout(() => {
        lastHeightRef.current =
          contentRef.current.offsetHeight + parseInt(topValue);
      }, 1);

      return () => {
        timeline.current.kill();
      };
    }, [displayItem.itemId, displayItem.duration, lastHeightRef, setList]);

    // Manage pop display
    // Type: 0,1,2 0 is negative, 1 is positive, 2 is caution
    // Icon: optional
    useEffect(() => {
      if (!created) {
        setCreated(true);
        let msgCode;
        let type;

        if (displayItem.result) {
          msgCode = displayItem.result;
          type = 1;
        } else if (displayItem.error) {
          msgCode = displayItem.error;
          type = 2;
        } else {
          msgCode = displayItem.code;
          type = displayItem.type;
        }

        setPopupDetails({
          msg: msgCode,
          replacements: displayItem?.replacements || false,
          extraMsg: displayItem?.msg ? displayItem.msg : "",
          raw: displayItem?.raw || false,
          type: type,
          value: displayItem?.value || null,
        });
      }
    }, [L, setPopupDetails, displayItem, created]);

    const replaceKeys = (replacements, raw = false) => {
      const replaceWithJSX = (key) => {
        switch (key) {
          case "CURRENCY_AMOUNT":
          case "TOKEN_AMOUNT":
            const amount =
              replacements?.["CURRENCY_AMOUNT"] || replacements?.[key];
            if (!amount) return null;

            return (
              <span className={st["value-display-container"]}>
                <ValueDisplay
                  amount={amount}
                  currencyCode={replacements?.CURRENCY}
                  size={"small"}
                  classType={"inline-popup"}
                  raw={raw}
                />
              </span>
            );

          case "CALENDAR_REWARD":
            return (
              <>
                <br />
                <span className={st["value-display-container"]}>
                  <ValueDisplay
                    amount={replacements[key]}
                    customFiatRate={1}
                    size={"small"}
                    classType={"inline-popup"}
                  />
                </span>
              </>
            );

          case "INSTANT_REWARD":
            return (
              <>
                <div className={st["horizontal-line-container"]}>
                  <div className={st["horizontal-line"]}></div>
                </div>
                <span className={st["value-display-container"]}>
                  <ValueDisplay
                    amount={replacements[key]}
                    customFiatRate={1}
                    size={"small"}
                    classType={"inline-popup"}
                  />
                </span>
              </>
            );

          case "CRYPTO_AMOUNT":
            return (
              <span className={st["value-display-container"]}>
                <span>
                  <ValueIcons
                    code={replacements["CRYPTO_CODE"]}
                    size={"small"}
                  />
                </span>
                {replacements[key]}
              </span>
            );
          case "TOKEN_ICON":
            return (
              <span className={st["value-display-container"]}>
                <span className={st["token-content"]}>
                  <CurrencyIcons currency={activeRate} size={"small"} />
                </span>
              </span>
            );

          default:
            return replacements[key];
        }
      };

      // Define the keys you want to replace with JSX
      const additions = [
        "TOKEN_AMOUNT",
        "CURRENCY_AMOUNT",
        "CALENDAR_REWARD",
        "CRYPTO_AMOUNT",
        "TOKEN_ICON",
        "INSTANT_REWARD",
      ].reduce((acc, key) => {
        if (replacements?.[key]) return { ...acc, [key]: replaceWithJSX(key) };
        return acc;
      }, {});

      let objsent = { ...replacements, ...additions };

      return objsent;
    };

    return (
      <div
        ref={contentRef}
        className={
          st["popup-content"] +
          " popup-class " +
          st[`popup-type-${popupDetails.type}`]
        }
        onMouseOver={() => timeline.current.pause()}
        onMouseLeave={() => timeline.current.play()}
        onTouchStart={() => timeline.current.pause()}
        onTouchEnd={() => timeline.current.play()}
        onClick={() => timeline.current.progress(0.98)}
      >
        <div className={st["flex-container"]}>
          {popupDetails.type === 1 ? (
            <div className={st["icon-container"]}>
              <svg
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
                enableBackground="new 0 0 512 512"
              >
                <path
                  d="M256 32C132.3 32 32 132.3 32 256s100.3 224 224 224 224-100.3 224-224S379.7 32 256 32zm114.9 149.1L231.8 359.6c-1.1 1.1-2.9 3.5-5.1 3.5-2.3 0-3.8-1.6-5.1-2.9-1.3-1.3-78.9-75.9-78.9-75.9l-1.5-1.5c-.6-.9-1.1-2-1.1-3.2 0-1.2.5-2.3 1.1-3.2.4-.4.7-.7 1.1-1.2 7.7-8.1 23.3-24.5 24.3-25.5 1.3-1.3 2.4-3 4.8-3 2.5 0 4.1 2.1 5.3 3.3 1.2 1.2 45 43.3 45 43.3l111.3-143c1-.8 2.2-1.4 3.5-1.4 1.3 0 2.5.5 3.5 1.3l30.6 24.1c.8 1 1.3 2.2 1.3 3.5.1 1.3-.4 2.4-1 3.3z"
                  fill="#4BAE4F"
                ></path>
              </svg>
            </div>
          ) : (
            <div
              className={`${
                popupDetails.type === 2 ? st["icon-rotation"] : null
              } ${st["icon-container"]}`}
            >
              <svg viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill="#E24C4B"
                  d="M13 0a13 13 0 1 0 0 26 13 13 0 0 0 0-26Zm-1 7a1 1 0 0 1 2 0v7a1 1 0 0 1-2 0Zm1 13a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Z"
                />
              </svg>
            </div>
          )}

          <div className={st["icon-text-content"]}>
            <span>
              {popupDetails?.replacements ? (
                L(
                  popupDetails.msg,
                  replaceKeys(popupDetails?.replacements, popupDetails?.raw)
                )
              ) : popupDetails.msg !== undefined ? (
                <span>{L(popupDetails.msg)}</span>
              ) : (
                ""
              )}
              {popupDetails?.extraMsg}
              {popupDetails?.value ? (
                <div className={st["value-display-container"]}>
                  <ValueDisplay
                    amount={popupDetails?.value}
                    size={"small"}
                    classType={"inline-popup-icon"}
                  />
                </div>
              ) : null}
            </span>
          </div>

          <div className={st["close-icon"]}>
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.40994 6.99994L12.7099 2.70994C12.8982 2.52164 13.004 2.26624 13.004 1.99994C13.004 1.73364 12.8982 1.47825 12.7099 1.28994C12.5216 1.10164 12.2662 0.99585 11.9999 0.99585C11.7336 0.99585 11.4782 1.10164 11.2899 1.28994L6.99994 5.58994L2.70994 1.28994C2.52164 1.10164 2.26624 0.99585 1.99994 0.99585C1.73364 0.99585 1.47824 1.10164 1.28994 1.28994C1.10164 1.47825 0.995847 1.73364 0.995847 1.99994C0.995847 2.26624 1.10164 2.52164 1.28994 2.70994L5.58994 6.99994L1.28994 11.2899C1.19621 11.3829 1.12182 11.4935 1.07105 11.6154C1.02028 11.7372 0.994141 11.8679 0.994141 11.9999C0.994141 12.132 1.02028 12.2627 1.07105 12.3845C1.12182 12.5064 1.19621 12.617 1.28994 12.7099C1.3829 12.8037 1.4935 12.8781 1.61536 12.9288C1.73722 12.9796 1.86793 13.0057 1.99994 13.0057C2.13195 13.0057 2.26266 12.9796 2.38452 12.9288C2.50638 12.8781 2.61698 12.8037 2.70994 12.7099L6.99994 8.40994L11.2899 12.7099C11.3829 12.8037 11.4935 12.8781 11.6154 12.9288C11.7372 12.9796 11.8679 13.0057 11.9999 13.0057C12.132 13.0057 12.2627 12.9796 12.3845 12.9288C12.5064 12.8781 12.617 12.8037 12.7099 12.7099C12.8037 12.617 12.8781 12.5064 12.9288 12.3845C12.9796 12.2627 13.0057 12.132 13.0057 11.9999C13.0057 11.8679 12.9796 11.7372 12.9288 11.6154C12.8781 11.4935 12.8037 11.3829 12.7099 11.2899L8.40994 6.99994Z"
                fill="rgba(255, 255, 255, 0.75)"
              />
            </svg>
          </div>
        </div>

        <div>
          <div className={st["loading-bar"]}>
            <div ref={barRef} className={st["bar"]}></div>
          </div>
        </div>
      </div>
    );
  }
);

PopupItem.displayName = "PopupItem";
