import React, { useEffect, useState, useRef, useCallback } from "react";

import gsap from "gsap";
import { useRecoilState } from "recoil";

import { PopupItem } from "./PopupItem";
import popupAtom from "../../recoil/popup";

import st from "../../styles/components/popup.module.scss";

PopupItem.displayName = "PopupItem";

export default function Popup() {
  const [popups, setPopups] = useRecoilState(popupAtom);
  const [list, setList] = useState([]);
  const [heightPOS, setHeightPOS] = useState(1);
  const lastHeightRef = useRef(0);
  const refs = useRef({});

  useEffect(() => {
    if (list.length === 0) {
      setHeightPOS(0);
      lastHeightRef.current = 0;
    }
  }, [list, setHeightPOS]);

  const createPopup = useCallback(
    (popups) => {
      const msgCode = popups.result || popups.error || popups.code;

      const duplicateItem = list.find(
        (item) => item.props.displayItem.code === msgCode,
      );

      if (duplicateItem) {
        // Access the ref of the duplicate item and call its method
        const duplicateRef =
          refs.current[duplicateItem.props.displayItem.itemId];

        if (duplicateRef.current && duplicateRef.current.myMethod) {
          duplicateRef.current.myMethod();
        }
        return;
      }

      const randomNum = gsap.utils.random(0, 1000000);
      const randomNumTwo = gsap.utils.random(0, 1000000);
      setHeightPOS((item) => item + 1);
      const newItemId = randomNum + "-" + randomNumTwo;
      refs.current[newItemId] = React.createRef();
      let clone = JSON.parse(JSON.stringify(popups));
      clone.itemId = newItemId;

      setList((items) => [
        <PopupItem
          ref={refs.current[newItemId]}
          setList={setList}
          pos={heightPOS}
          list={list}
          id={list.length}
          key={newItemId}
          displayItem={clone}
          lastHeightRef={lastHeightRef}
        />,
        ...items,
      ]);
    },
    [heightPOS, list],
  );

  useEffect(() => {
    if (!popups || popups.length === 0) return;

    createPopup(popups[0]);
    for (let i = 1; i < popups.length; i++) {
      setTimeout(
        () => {
          createPopup(popups[i]);
        },
        10 + i * 500,
      );
    }

    setPopups([]);
  }, [createPopup, popups, setPopups]);

  return <div className={st["popup-container"]}>{list}</div>;
}
